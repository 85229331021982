<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>{{$store.state.table.kategoriPenilaian}}</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" v-b-modal.modal-input
                  ><CIcon name="cil-plus" /> {{$store.state.table.tambahModal}}</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  :label="$store.state.table.perHalaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  :label="$store.state.table.cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      :placeholder="$store.state.table.cariHolder"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >{{$store.state.table.hapusCari}}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >

                <template #cell(no)="item">
                  {{item.index + 1}}
                </template>
                  <template #cell(actions)="item">
                    <b-button
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      v-b-modal.modal-edit
                      @click="data= item.item"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    >

                    <b-button
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete
                      @click="data= item.item"
                      ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal-input @alert="triggerAlert($event)" />
    <modal-edit @alert="triggerAlert($event)" :data="data" />
    <modal-delete @alert="triggerAlert($event)" :data="data" />
  </div>
</template>

<script>
import axios from "axios"
import {ipBackend} from "@/ipBackend.js"
import modalInput from "../../../component/hcms/master/kategoriPenilaian/modalInput.vue";
import modalEdit from "../../../component/hcms/master/kategoriPenilaian/modalEdit.vue";
import modalDelete from "../../../component/hcms/master/kategoriPenilaian/modalDelete.vue";

export default {
  components: {
    modalInput,
    modalEdit,
    modalDelete,
  },
  name: "kategoriPenilaian",
  data() {
    return {
      items: [],
      data:[],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKategoriPenilaian",
          label: "Kategori Penilaian",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
      msg:"",
      showing:false,
      variant:""
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted(){  
      this.getKatNilai()
  },  
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getKatNilai(){
      let vm = this
      vm.tableBusy = true
      let kat = await axios.get(ipBackend + 'masterKategoriPenilaian/list')

      vm.items= kat.data.data
      vm.totalRows = kat.data.data.length
      vm.tableBusy = false
    },
     triggerAlert(event) {
      let vm = this;
      // vm.showing = event.showing;
      // vm.variant = event.variant;
      // vm.msg = event.msg;
      vm.$store.commit("set_alert", event);
      vm.getKatNilai();
      // setTimeout(() => {
      //   vm.showing = false;
      // }, 4000);
    },
  },
};
</script>
